import type { GetMediaFilesInput, MediaFileDto, QuickUploadResponse } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaFileService {
  apiName = 'Default';
  

  create = (id: string, upload: any, config?: Partial<Rest.Config>) =>
    this.restService.request<any, QuickUploadResponse>({
      method: 'POST',
      url: `/api/app/media-files/${id}`,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, sourceDomainId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/media-files/${id}/${sourceDomainId}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MediaFileDto>({
      method: 'GET',
      url: `/api/app/media-files/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetMediaFilesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MediaFileDto>>({
      method: 'GET',
      url: '/api/app/media-files',
      params: { filterText: input.filterText, locationIdentifier: input.locationIdentifier, fileType: input.fileType, fileSizeMin: input.fileSizeMin, fileSizeMax: input.fileSizeMax, storageType: input.storageType, name: input.name, contentType: input.contentType, fileName: input.fileName, sorting: input.sorting, maxResultCount: input.maxResultCount, skipCount: input.skipCount },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
